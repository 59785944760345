import React from 'react';
import PropTypes from 'prop-types';

const Bell = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 24"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M0 20.4889V18.6135H2.2606V9.2825C2.2606 7.60078 2.77966 6.11382 3.81779 4.82163C4.85571 3.52944 6.18833 2.70297 7.81565 2.34219V1.56313C7.81565 1.129 7.96749 0.759891 8.27115 0.45581C8.57481 0.151937 8.94351 0 9.37722 0C9.81115 0 10.1804 0.151937 10.4849 0.45581C10.7896 0.759891 10.9419 1.129 10.9419 1.56313V2.34219C12.5692 2.70297 13.9019 3.52944 14.9398 4.82163C15.9779 6.11382 16.497 7.60078 16.497 9.2825V18.6135H18.7576V20.4889H0ZM9.3766 24C8.75447 24 8.22259 23.7787 7.78095 23.336C7.33911 22.8933 7.11819 22.3611 7.11819 21.7394H11.6394C11.6394 22.3632 11.4178 22.8959 10.9747 23.3375C10.5316 23.7792 9.99893 24 9.3766 24ZM4.13636 18.6135H14.6212V9.2825C14.6212 7.83483 14.1094 6.59923 13.0859 5.57569C12.0622 4.55194 10.8265 4.04007 9.37879 4.04007C7.93112 4.04007 6.69541 4.55194 5.67166 5.57569C4.64813 6.59923 4.13636 7.83483 4.13636 9.2825V18.6135Z" />
    </svg>
  );
};

Bell.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Bell;
