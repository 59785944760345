import React from 'react';
import PropTypes from 'prop-types';

const Bundling = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M2 12.9c1.4 1.3 3 2 5 2a6.7 6.7 0 0 0 4.8-2l-1.1-1.1a5.6 5.6 0 0 1-3.8 1.5A5.3 5.3 0 0 1 1.5 8c0-1.5.6-2.7 1.6-3.8 1-1 2.3-1.6 3.8-1.6s2.8.6 3.9 1.6c1 1 1.5 2.3 1.5 3.8v.2l-1-1-1 1 2.8 3L16 8l-1.1-1-1 1v-.2a6.7 6.7 0 0 0-2-4.9 7 7 0 0 0-5-2A6.7 6.7 0 0 0 2 3a7 7 0 0 0-2 5c0 1.9.7 3.5 2 4.9Z" />
      <path d="M7 5 4.5 6.7l1.1.7 2.5-1.7L7 5ZM6 7.6l2.3-1.7 1 .8L7 8.3l-1-.7ZM6.8 8.6 4.4 7v2.5l2.4 1.7V8.6ZM9.5 7 7 8.6v2.6l2.4-1.7V7Z" />
    </svg>
  );
};

Bundling.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Bundling;
