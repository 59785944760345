import React from 'react';
import PropTypes from 'prop-types';

const ExclamationFilled = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M8 12c.2 0 .4 0 .6-.2l.2-.6c0-.2 0-.4-.2-.6a.8.8 0 0 0-.6-.2c-.2 0-.4 0-.6.2l-.2.6c0 .2 0 .4.2.6l.6.2Zm-.8-3.2h1.6V4H7.2v4.8ZM8 16a7.8 7.8 0 0 1-5.7-2.3A8 8 0 0 1 .6 4.9 8 8 0 0 1 8 0a7.8 7.8 0 0 1 5.7 2.3 8 8 0 0 1 1.7 8.8A8 8 0 0 1 8 16Z" />
    </svg>
  );
};

ExclamationFilled.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default ExclamationFilled;
