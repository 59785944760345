import React from 'react';
import PropTypes from 'prop-types';

const Minus = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M.5 8c0-.6.4-1 1-1h14a1 1 0 1 1 0 2h-14a1 1 0 0 1-1-1Z" />
    </svg>
  );
};

Minus.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Minus;
