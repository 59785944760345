import React from 'react';
import PropTypes from 'prop-types';

const Bundled = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m6.9 0-6 4 2.6 1.7 6-4L6.8 0ZM4.2 6l5.7-3.8L12.5 4 6.7 7.7 4.2 6.1ZM6.4 8.4.5 4.6v6l5.9 4V8.3ZM12.9 4.6 7 8.4v6.1l1.8-1.2a4.4 4.4 0 0 1 4-6V4.6Z" />
      <path d="m12.4 13.3 2.5-2.5-.8-.8-1.7 1.7-.7-.7-.8.8 1.5 1.5Zm.5 2c-1 0-1.9-.4-2.6-1-.7-.8-1-1.7-1-2.7 0-1 .3-1.8 1-2.5S12 8 13 8s1.9.4 2.6 1c.7.8 1 1.6 1 2.6s-.3 1.9-1 2.6c-.7.7-1.6 1-2.6 1Z" />
    </svg>
  );
};

Bundled.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Bundled;
