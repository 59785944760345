import React from 'react';
import PropTypes from 'prop-types';

const Flag = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M1 16V0h13.5l-1.7 4.2 1.7 4.2H2.7V16H1Z" />
    </svg>
  );
};

Flag.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Flag;
