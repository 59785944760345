import React from 'react';
import PropTypes from 'prop-types';

const Tailored = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M12 14.3954L9.60461 12L12 9.60461L14.3954 12L12 14.3954ZM9.4549 7.56613L6.94453 5.05577L12 0L17.0555 5.05577L14.5451 7.56613L12 5.02103L9.4549 7.56613ZM5.05577 17.0555L0 12L5.05577 6.94453L7.56613 9.4549L5.02103 12L7.56613 14.5451L5.05577 17.0555ZM18.9442 17.0555L16.4339 14.5451L18.979 12L16.4339 9.4549L18.9442 6.94453L24 12L18.9442 17.0555ZM12 24L6.94453 18.9442L9.4549 16.4339L12 18.979L14.5451 16.4339L17.0555 18.9442L12 24Z" />
    </svg>
  );
};

Tailored.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Tailored;
