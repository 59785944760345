import React from 'react';
import PropTypes from 'prop-types';

const Bonus = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.7 15.9a1.6 1.6 0 0 0 1.2 0l.6-.4 7-7a1.6 1.6 0 0 0 .5-1.2V1.6c0-.4-.2-.8-.5-1.1-.3-.3-.7-.5-1.1-.5H8.6L8 .1l-.5.4-7 7-.4.6a1.7 1.7 0 0 0 0 1.2c0 .2.2.3.4.5l5.7 5.7.5.4Zm.5-3.9h1.6v-.8h.8c.2 0 .4 0 .6-.2l.2-.6V8l-.3-.6a.8.8 0 0 0-.5-.2H7.2v-.8h3.2V4.8H8.8V4H7.2v.8h-.8c-.2 0-.4 0-.6.2l-.2.6V8c0 .2 0 .4.2.6l.6.2h2.4v.8H5.6v1.6h1.6v.8Z"
      />
    </svg>
  );
};

Bonus.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Bonus;
