import React from 'react';
import PropTypes from 'prop-types';

const PaperMap = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_84836_12030)">
        <path
          d="M16.2353 23.8913L7.76471 20.9266L1.68882 23.2775C1.27259 23.4387 0.887059 23.3926 0.532235 23.1392C0.177412 22.8858 0 22.5392 0 22.0994V3.58377C0 3.30494 0.0747061 3.05471 0.224118 2.83306C0.373294 2.61141 0.582824 2.45082 0.852706 2.35129L7.76471 0L16.2353 2.96471L22.3112 0.613765C22.7274 0.452589 23.1129 0.491884 23.4678 0.731649C23.8226 0.971413 24 1.30671 24 1.73753V20.3619C24 20.6496 23.9186 20.9021 23.7558 21.1193C23.5927 21.3365 23.3719 21.4948 23.0933 21.5944L16.2353 23.8913ZM15.1765 21.3014V4.78377L8.82353 2.56271V19.0804L15.1765 21.3014ZM17.2941 21.3014L21.8824 19.7809V3.05153L17.2941 4.78377V21.3014ZM2.11765 20.8398L6.70588 19.0804V2.56271L2.11765 4.11035V20.8398Z"
          fill="#FF8300"
        />
      </g>
      <defs>
        <clipPath id="clip0_84836_12030">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

PaperMap.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default PaperMap;
