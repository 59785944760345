import React from 'react';
import PropTypes from 'prop-types';

const AutoBonusWarning = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.68 4.9H9.33a1.8 1.8 0 0 0-1.23.52L.5 13.05c-.18.17-.3.37-.38.58a1.87 1.87 0 0 0 0 1.29c.08.2.2.4.38.57l6.17 6.18a1.73 1.73 0 0 0 2.47 0l6.96-6.96a6.72 6.72 0 0 1-2.42-9.8Zm-4.2 12.97H7.75V17H6.03v-1.73h3.45v-.86H6.9a.84.84 0 0 1-.62-.25.84.84 0 0 1-.24-.61v-2.6a.84.84 0 0 1 .86-.86h.86v-.86h1.73v.86h1.73v1.73H7.75v.86h2.6c.24 0 .45.08.61.25.17.17.25.37.25.62v2.59c0 .24-.08.45-.25.61a.84.84 0 0 1-.61.25h-.87v.87Z"
      />
      <path d="M19.2 11.63c.13 0 .24-.05.34-.15a.46.46 0 0 0 0-.67.46.46 0 0 0-.68 0 .46.46 0 0 0 0 .67c.1.1.21.15.34.15Zm-.48-1.92h.96V5.87h-.96V9.7Zm.48 3.84a4.63 4.63 0 0 1-3.4-1.4 4.63 4.63 0 0 1-1.4-3.4c0-1.33.47-2.46 1.4-3.4a4.63 4.63 0 0 1 3.4-1.4c1.33 0 2.46.46 3.4 1.4.93.94 1.4 2.07 1.4 3.4 0 1.32-.47 2.46-1.4 3.4a4.63 4.63 0 0 1-3.4 1.4Z" />
    </svg>
  );
};

AutoBonusWarning.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default AutoBonusWarning;
