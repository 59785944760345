import React from 'react';
import PropTypes from 'prop-types';

const Courier = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 15"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m11.42 9.5.68 2.1c.05.14.21.22.36.18l2.44-.8c.14-.04.22-.2.18-.35l-.68-2.1a.28.28 0 0 0-.36-.18l-2.44.79a.28.28 0 0 0-.18.35ZM11.1 8.51c.05.15.21.23.36.19l1.8-.59c.14-.04.23-.2.18-.35l-.5-1.54a.28.28 0 0 0-.35-.18l-1.8.59a.28.28 0 0 0-.18.35l.5 1.53Z" />
      <path d="M15.98 11.66a.39.39 0 0 0-.49-.25l-3.32 1.08c-.14-.1-.29-.17-.46-.2L9.9 6.7a.39.39 0 0 0-.37-.26H8.5a.8.8 0 0 0-.67-.38h-1.2c-.38-.48-.98-1.22-1.25-1.46a.83.83 0 0 0-.22-.13L3.84 3.9a1.28 1.28 0 0 0-1.72.83l-.97 3.12a.97.97 0 0 0 .27 1.02l2.15 1.97.27 2.86a.97.97 0 1 0 1.93-.19l-.3-3.22a.97.97 0 0 0-.31-.62L3.92 8.53l.86-2.2c.25.3.56.68.82 1.02.15.2.39.31.64.31h1.6a.8.8 0 0 0 .72-.46h.69l1.69 5.22a1.15 1.15 0 0 0 .56 2.16 1.15 1.15 0 0 0 1.12-1.42l3.11-1.01c.2-.07.32-.29.25-.5v.01ZM11.5 13.8a.38.38 0 1 1 0-.75.38.38 0 0 1 0 .75ZM5.1 3.85a1.71 1.71 0 1 0 0-3.43 1.71 1.71 0 0 0 0 3.43Z" />
      <path d="m1.52 9.84-.29 1.27-1.11 2.03a.97.97 0 0 0 1.7.93L3 11.93l.02-.04-.07-.74-1.43-1.3Z" />
    </svg>
  );
};

Courier.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Courier;
