import React from 'react';
import PropTypes from 'prop-types';

const Bills = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_84836_12024)">
        <path
          d="M14.0682 12.5681C13.2425 12.5681 12.5436 12.2821 11.9716 11.7101C11.3996 11.1381 11.1136 10.4392 11.1136 9.61352C11.1136 8.78781 11.3996 8.08896 11.9716 7.51695C12.5436 6.94495 13.2425 6.65894 14.0682 6.65894C14.8939 6.65894 15.5928 6.94495 16.1648 7.51695C16.7368 8.08896 17.0228 8.78781 17.0228 9.61352C17.0228 10.4392 16.7368 11.1381 16.1648 11.7101C15.5928 12.2821 14.8939 12.5681 14.0682 12.5681ZM6.27256 16.2273C5.68519 16.2273 5.18232 16.0181 4.76395 15.5998C4.34559 15.1814 4.1364 14.6784 4.1364 14.0909V5.13616C4.1364 4.54879 4.34559 4.04592 4.76395 3.62755C5.18232 3.20918 5.68519 3 6.27256 3H21.8635C22.4511 3 22.9541 3.20918 23.3724 3.62755C23.7908 4.04592 24 4.54879 24 5.13616V14.0909C24 14.6784 23.7908 15.1814 23.3724 15.5998C22.9541 16.0181 22.4511 16.2273 21.8635 16.2273H6.27256ZM8.0453 14.4546H20.0911C20.0911 13.8652 20.3003 13.3618 20.7187 12.9442C21.137 12.5268 21.6399 12.3181 22.2273 12.3181V6.9089C21.6379 6.9089 21.1346 6.69972 20.7172 6.28135C20.2998 5.86298 20.0911 5.36011 20.0911 4.77274H8.0453C8.0453 5.36208 7.83612 5.86544 7.41775 6.28283C6.99938 6.70021 6.49652 6.9089 5.90915 6.9089V12.3181C6.49849 12.3181 7.00185 12.5273 7.41923 12.9457C7.83661 13.3641 8.0453 13.867 8.0453 14.4546ZM20.4773 20.3637H2.13645C1.54889 20.3637 1.04592 20.1546 0.627551 19.7362C0.209184 19.3178 0 18.8148 0 18.2273V6.52274H1.77274V18.2273C1.77274 18.3181 1.81056 18.4014 1.8862 18.4772C1.96203 18.5531 2.04545 18.591 2.13645 18.591H20.4773V20.3637ZM6.27256 14.4546H5.90915V4.77274H6.27256C6.17407 4.77274 6.08888 4.80869 6.01699 4.88059C5.94509 4.95248 5.90915 5.03767 5.90915 5.13616V14.0909C5.90915 14.1894 5.94509 14.2746 6.01699 14.3464C6.08888 14.4185 6.17407 14.4546 6.27256 14.4546Z"
          fill="#FF8300"
        />
      </g>
      <defs>
        <clipPath id="clip0_84836_12024">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Bills.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Bills;
