import React from 'react';
import PropTypes from 'prop-types';

const AutoBonus = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.86 4.72-.17-.01H9.42a1.81 1.81 0 0 0-1.24.52L.5 12.93c-.17.18-.3.38-.38.6a1.8 1.8 0 0 0 0 1.3c.08.2.2.4.38.57l6.24 6.24a1.74 1.74 0 0 0 2.49 0l5.75-5.75h-1.5a1.1 1.1 0 0 1-1.04-1.45l3.42-9.72ZM9.58 17.8H7.83v-.87H6.1v-1.75h3.49v-.87H6.96a.84.84 0 0 1-.62-.25.84.84 0 0 1-.25-.62v-2.62c0-.25.08-.46.25-.62a.84.84 0 0 1 .62-.25h.87v-.88h1.75v.88h1.74v1.74H7.83v.87h2.62c.25 0 .45.09.62.25.17.17.25.38.25.63v2.61c0 .25-.08.46-.25.63a.84.84 0 0 1-.62.25h-.87v.87Z"
      />
      <path d="m14.62 14 3.57-10.05h2.24L24 14h-2.12l-.78-2.24h-3.58L16.74 14h-2.12Zm3.4-3.74h2.57l-1.28-4.08-1.29 4.08Z" />
    </svg>
  );
};

AutoBonus.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default AutoBonus;
