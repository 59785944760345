import React from 'react';
import PropTypes from 'prop-types';

const SmartOrderMatching = ({
  className,
  fill = 'currentColor',
  size = 16
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.1 5.5c-.7 1.1-1 2.3-1.2 3.6h16A7.7 7.7 0 0 0 13 3L14.3.7l.1-.4-.2-.2C14 0 14 0 13.8 0l-.3.1-1.3 2.3a8.5 8.5 0 0 0-6.5 0L4.3.2 4 0h-.3l-.2.3v.4L5 3C3.7 3.6 2.8 4.4 2 5.5ZM6 6.8c-.1.2-.4.3-.6.3-.3 0-.5-.1-.7-.3a.9.9 0 0 1-.2-.6c0-.3 0-.5.2-.6.2-.2.4-.3.7-.3.2 0 .5 0 .6.3.2.1.3.3.3.6 0 .2 0 .5-.3.6Zm7.3 0-.6.3c-.3 0-.5-.1-.7-.3a.9.9 0 0 1-.2-.6c0-.3 0-.5.2-.6.2-.2.4-.3.7-.3.2 0 .4 0 .6.3.2.1.3.3.3.6 0 .2-.1.5-.3.6Z"
      />
      <path d="M1 14.4H2l.1.3.2.2.3.1h.9l.2-.2.1-.3c0-.2 0-.3-.2-.4l-.5-.2-.8-.2-.7-.2-.5-.5-.2-.7c0-.4.1-.7.3-.9L2 11c.3-.2.7-.2 1-.2.5 0 .9 0 1.2.2.3.1.5.3.6.5.2.3.3.5.3.8H3.9l-.1-.3-.3-.2a1 1 0 0 0-.4-.1c-.3 0-.5 0-.6.2l-.2.4c0 .1 0 .2.2.3l.5.2.7.2c.3 0 .6 0 .8.2l.5.5.2.7c0 .4-.1.7-.3.9-.1.3-.4.4-.7.6L3 16a3 3 0 0 1-1-.1l-.6-.4c-.1-.1-.3-.3-.3-.5l-.2-.6Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6 13.6c0 .5-.1 1-.3 1.3a2 2 0 0 1-.8.8c-.3.2-.7.3-1.2.3s-1-.1-1.3-.3a2 2 0 0 1-.8-.8C6 14.5 6 14 6 13.6V13c0-.5.1-.9.3-1.2.2-.4.5-.7.8-.9l1.3-.2c.5 0 .9 0 1.2.3.4.1.6.4.8.8.2.3.3.7.3 1.2v.6ZM9.3 13l-.1-.7a1 1 0 0 0-.4-.5 1 1 0 0 0-.5-.1 1 1 0 0 0-.6.1 1 1 0 0 0-.3.5l-.2.7v.6c0 .3 0 .6.2.8 0 .2.2.3.3.4l.6.2c.2 0 .4 0 .5-.2.2 0 .3-.2.4-.4l.1-.8V13Z"
      />
      <path d="M11.6 16h1.1v-3.3L14 15h.7l1.1-2.4v3.2H17v-5.1h-1.3l-1.3 2.9-1.4-3h-1.3V16Z" />
    </svg>
  );
};

SmartOrderMatching.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default SmartOrderMatching;
