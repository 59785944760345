import React from 'react';
import PropTypes from 'prop-types';

const Star = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m3 15.2 1.4-5.6L0 5.8l5.8-.5L8 0l2.2 5.3 5.8.5-4.4 3.8 1.3 5.6-4.9-3-5 3Z" />
    </svg>
  );
};

Star.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Star;
